'use client';

import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { useGaContext } from '@/context/GoogleAnalyticsContext';

import styles from './styles.module.css';
import { PollOption } from './PollOption';
import { PollFooter } from './PollFooter';
import { mapApiData, type PollApi, type PollData } from './util';

interface PollAttributes {
	id: string;
	pollData: PollData;
}

const fetchData = async (id: string): Promise<null | PollApi> => {
	try {
		const fetchURL = `/.api/polls?pollId=${id}`;
		const response = await fetch(fetchURL);

		if (!response.ok) {
			return null;
		}

		const json = await response.json();
		return json as PollApi;
	} catch (error) {
		return null;
	}
};

export const Poll: FC<PollAttributes> = ({ id, pollData }) => {
	const { description, question } = pollData;

	const [closed, setClosed] = useState(pollData.closed);
	const [closedTime, setClosedTime] = useState(pollData.closedTime);
	const [selectedOption, setSelectedOption] = useState<null | string>(null);
	const [hasVoted, setHasVoted] = useState(false);
	const [voteCount, setVoteCount] = useState(pollData.totalVotes);
	const [options, setOptions] = useState(pollData.options);
	const { sendGaEvent } = useGaContext();
	const [isDataLoaded, setIsDataLoaded] = useState(false);

	useEffect(() => {
		if (isDataLoaded || !id) {
			return;
		}

		fetchData(id)
			.then((pollApiData) => {
				const mappedData = mapApiData(pollApiData);
				if (!mappedData) {
					return;
				}

				setClosed(mappedData.closed);
				setClosedTime(mappedData.closedTime);
				setOptions(mappedData.options);
				setVoteCount(mappedData.totalVotes);

				const storedVote = localStorage.getItem(`poll_voted:${id}`);
				if (!storedVote) {
					return;
				}

				setHasVoted(true);
				setSelectedOption(storedVote);
			})
			.finally(() => setIsDataLoaded(true));
	}, [id, isDataLoaded]);

	const handleVote = async () => {
		if (!selectedOption) {
			return;
		}

		try {
			const response = await fetch(
				`/.api/polls/vote?pollId=${id}&optionId=${selectedOption}`,
				{
					method: 'POST',
				},
			);

			if (!response.ok) {
				return;
			}

			sendGaEvent('poll-user-voted', {
				pollId: id,
				pollOption: selectedOption,
			});
		} catch (e) {
			return;
		}

		const newVoteCount = voteCount + 1;

		setHasVoted(true);
		setOptions((currOptions) =>
			currOptions.map((e) => {
				const newScore = e.id === selectedOption ? e.score + 1 : e.score;

				return {
					...e,
					percentage: ((newScore / newVoteCount) * 100).toFixed(),
					score: newScore,
				};
			}),
		);
		setVoteCount(newVoteCount);
		localStorage.setItem(`poll_voted:${id}`, selectedOption);
	};

	return (
		<form
			className={styles.poll}
			onSubmit={(e) => {
				e.preventDefault();
				handleVote();
			}}
		>
			<div className={styles.poll__heading}>
				<h4 className={styles.poll__question}>{question}</h4>
				{/* eslint-disable react/no-danger */}
				<p
					className={styles.poll__description}
					dangerouslySetInnerHTML={{
						__html: description,
					}}
				/>
				{/* eslint-enable react/no-danger */}
			</div>
			<div className={styles.poll__optionsContainer}>
				{options.map((option) => (
					<PollOption
						hasVoted={hasVoted}
						isClosed={closed}
						key={option.id}
						onChange={() => setSelectedOption(option.id)}
						option={option}
						selectedOption={selectedOption}
					/>
				))}
			</div>
			<PollFooter
				closedTime={closedTime}
				hasVoted={hasVoted}
				isClosed={closed}
				selectedOption={selectedOption}
				voteCount={voteCount}
			/>
		</form>
	);
};
