'use client';

import clsx from 'clsx';
import type { FC } from 'react';
import type { CardProps } from '@/types/card';
import { Label } from '@/components/raven/ui/Label';
import { getIconComponent } from '@/utils/get-icons';
import { decodeEntities } from '@/utils/post-helpers';
import { InternalLink } from '@/components/InternalLink';
import { useAppSettings } from '@/context/AppSettingsContext';
import { imageSizes } from '@/components/raven/Card/image-sizes';
import { formatReadableDate } from '@/utils/format-readable-date';
import { ArticleFeaturedImage } from '@/components/ArticleFeaturedImage';

import styles from './styles.module.css';

export const Card: FC<CardProps> = ({
	aspectRatio,
	author,
	button,
	category,
	className,
	date,
	image,
	link,
	mediaType = 'image',
	sizeOverride,
	title,
	variation,
}) => {
	const isImage = mediaType === 'image';
	const isVideo = mediaType === 'video';
	const isMedia = variation !== 'list-no-media';
	const is5050 = variation.includes('50-50');
	const isList = variation.includes('list');
	const isStacked = variation.includes('stacked');
	const isStackedLarge = variation === 'stacked-large';
	const sizes = isMedia ? sizeOverride || imageSizes[variation] : '';
	const appSettings = useAppSettings();
	const imageAspectRatio = aspectRatio?.replace('-', ':') as
		| `${number}:${number}`
		| undefined;

	const imageWrapperCx = clsx(styles.cardMedia, {
		[styles.cardMedia11]: aspectRatio === '1-1',
		[styles.cardMedia169]: aspectRatio === '16-9',
		[styles.cardMedia43]: is5050 || isStacked || aspectRatio === '4-3',
		[styles.cardMedia45]: aspectRatio === '4-5',
		[styles.cardMedia916]: isStackedLarge,
	});

	return (
		<article
			className={clsx(styles.card, className, `card--${variation}`, {
				[styles.fiftyFifty]: is5050,
				[styles.list]: isList,
				[styles.mediaBottom]: variation.includes('media-bottom'),
				[styles.mediaLeft]: variation.includes('media-left'),
				[styles.mediaRight]: variation.includes('media-right'),
				[styles.mediaTop]: variation.includes('media-top'),
				[styles.noMedia]: !isMedia,
				[styles.stacked]: isStacked,
				[styles.stackedLarge]: isStackedLarge,
			})}
		>
			{isMedia && isImage && image && (
				<figure className={imageWrapperCx}>
					<ArticleFeaturedImage
						alt={image.alt_text ?? ''}
						aspectRatio={imageAspectRatio}
						className={styles.cardImage}
						fill
						focalX={image.meta?.focal_point?.x}
						focalY={image.meta?.focal_point?.y}
						sizes={sizes}
						src={image.source_url}
					/>
				</figure>
			)}

			<div
				className={clsx(styles.cardContent, {
					[styles.cardContentIsVideo]: isVideo,
				})}
			>
				{category && (
					<div className={styles.cardCategory}>
						<Label
							className={styles.cardCategoryLabel}
							text={decodeEntities(category)}
						/>
					</div>
				)}

				<h2 className={styles.cardTitle}>
					<InternalLink className={styles.cardTitleLink} href={link}>
						{decodeEntities(title)}
					</InternalLink>
				</h2>

				{(author || date) && (
					<div className={styles.cardMetadata}>
						{author && <p className={styles.cardContributor}>{author}</p>}

						{date && (
							<time className={styles.cardDate} dateTime={date}>
								{formatReadableDate(date, {
									timeZone: appSettings.settings.timezone,
								})}
							</time>
						)}
					</div>
				)}

				{button && (button.label || button.icon) && (
					<span
						className={clsx(styles.cardButton, 'button', {
							'button--secondary': is5050,
							'button--tertiary': !is5050,
						})}
					>
						{button.label && button.label}
						{button.icon && getIconComponent(button.icon, 16)}
					</span>
				)}
			</div>
		</article>
	);
};
